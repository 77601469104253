import _merge from 'lodash.merge';
import { PartialDeep } from 'type-fest';

import { HOSTNAME_REGEX, Maybe, NonNullableKeys } from '../index.js';

export const stringToUrl = (str: string) =>
  str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/&/g, '-and-')
    .replace(/[^a-z0-9-]/g, '')
    .replace(/-+/g, '-')
    .replace(/^-*/, '')
    .replace(/-*$/, '');

export const inEnum = <E extends string>(enu: Record<string, E>, value: Maybe<string>): value is E =>
  Object.values(enu).includes(value as E);

export const isDayValid = (dayNumber: number) => dayNumber >= 0 && dayNumber <= 6;
export const isHourValid = (hourNumber: number) => hourNumber >= 0 && hourNumber < 24;

export const isIDValid = (id: number, allowNegative?: boolean) => !(!allowNegative && id <= 0);

export const isEmailValid = (email: string) =>
  !!email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  );
export const isPhoneValid = (phone: string) => !!phone.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);

export const merge = <T>(first: T, second: PartialDeep<T>) => _merge({}, first, second);

export const getHostname = (url: string) => url.match(HOSTNAME_REGEX)?.[1];

export const join = <T>(array: T[], { separator = ',', stopsOnFalsy }: { separator?: string; stopsOnFalsy?: boolean }) => {
  let result = '';
  array.every((item) => {
    if (item) {
      if (result) {
        result += ` ${separator} `;
      }
      result += item;
    } else if (stopsOnFalsy) {
      return false;
    }
    return true;
  });
  return result;
};

/**
 * Ensures that the given object has the given key and that the value is not null or undefined.
 * @param obj object
 * @param key key
 * @returns boolean - type guard
 */
// TypeScript doesn't like "NonNullableKeys<T, K>" as a type guard
// even though it's a valid type and does the job
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ensureObjectProperty = <T, K extends keyof T>(obj: T, key: K): obj is NonNullableKeys<T, K> => obj[key] != null;

/**
 * Decode a URL state string into an object.
 * @param state
 */
export function decodeURLState<T>(state: string): T;
export function decodeURLState<T>(state: string | undefined): T | undefined;
export function decodeURLState<T>(state: string | undefined): T | undefined {
  return state ? JSON.parse(decodeURIComponent(state)) : undefined;
}

/**
 * Encode an object into a URL state string.
 * @param state
 */
export function encodeURLState<T>(state: Record<string, T>): string;
export function encodeURLState<T>(state: Record<string, T> | undefined): string | undefined;
export function encodeURLState<T>(state: Record<string, T> | undefined) {
  return state ? encodeURIComponent(JSON.stringify(state)) : undefined;
}

/**
 * Adds akamai token to the given URL. (via param `hdnts`)
 * @param url
 * @param token
 */
export function createAkamaiUrlWithToken(url: string | URL, token: string): string {
  const u = new URL(url);
  u.searchParams.set('hdnts', token);
  // decodeURIComponent is used to avoid double encoding which Akamai doesn't like
  return decodeURIComponent(u.toString());
}

/**
 * Create a URL templated variable. (i.e. width -> {width})
 * @param key
 * @returns
 */
export const createUrlTemplatedVariable = (key: string) => `{${key}}`;
